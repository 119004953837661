import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-slider">
        <div className="slide slide1">
          <div className="text-slide1">
            <h3>Flexible I.T. services for your business</h3>
          </div>
        </div>
        <div className="slide slide2">
          <div className="text-slide2">
            <h3>We are Experts in Providing & Managing IT Services</h3>
          </div>
        </div>
        <div className="slide slide3">
          <div className="text-slide3">
            <h3>Offering World Class Corporate Training Services</h3>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default HeroSection;
