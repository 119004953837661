import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Clients from "./pages/Clients";
import Features from "./pages/Features";
import ECommerce from "./pages/ECommerce";
import Commercial from "./pages/Commercial";
import Education from "./pages/Education";
import Sales from "./pages/Sales";
import Finance from "./pages/Finance";
import Inventory from "./pages/Inventory";
import Organisation from "./pages/Organisation";
import Tourism from "./pages/Tourism";
import Marketing from "./pages/Marketing";
import Careers from "./pages/Careers";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Portfolios from "./pages/Portfolios";
import Services from "./pages/Services";
import "./App.css";

function App() {
  return (
    <div className="app-container">
      <Router>
        <Navbar />
        <div className="page-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/portfolios" element={<Portfolios />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/features" element={<Features />}>
              <Route index element={<ECommerce />} />
              <Route path="ecommerce" element={<ECommerce />} />
              <Route path="commercial" element={<Commercial />} />
              <Route path="education" element={<Education />} />
              <Route path="sales" element={<Sales />} />
              <Route path="finance" element={<Finance />} />
              <Route path="inventory" element={<Inventory />} />
              <Route path="organisation" element={<Organisation />} />
              <Route path="tourism" element={<Tourism />} />
              <Route path="marketing" element={<Marketing />} />
            </Route>
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
