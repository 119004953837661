import React from 'react';
import o1 from '../assets/o1.png';
import o2 from '../assets/o2.jpg';
import o3 from '../assets/o3.jpg';
import o4 from '../assets/o4.jpg';
import o5 from '../assets/o5.jpg';
import o6 from '../assets/o6.jpg';
import o7 from '../assets/o7.jpg';
import o8 from '../assets/o8.jpg';
import o9 from '../assets/o9.jpg';
import './Orgmanagement.css';


const Orgmanagement = () => {
  return (
    <div className='container6'>
      <main className='content6'>
         <h1>Organisation Management System</h1>
        <div className="card-grid6">
          <div className="card6">
            <img src={o1} alt="Dues" />
            <p>Dues Management</p>
          </div>
          <div className="card6">
            <img src={o2} alt="Fundraising" />
            <p>Fundraising</p>
          </div>
          <div className="card6">
            <img src={o3} alt="Event management" />
            <p>Event Management</p>
          </div>
          <div className="card6">
            <img src={o4} alt="Mobile capabilities" />
            <p>Mobile capabilities</p>
          </div>
          <div className="card6">
            <img src={o5} alt="Newsletters" />
            <p>Newsletters</p>
          </div>
          <div className="card6">
            <img src={o6} alt="Mobile capabilities" />
            <p>Mobile capabilities</p>
          </div>
          <div className="card6">
            <img src={o7} alt="Member text messaging" />
            <p>Member text messaging</p>
          </div>
          <div className="card6">
            <img src={o8} alt="Member database" />
            <p>Member database</p>
          </div>
          <div className="card6">
            <img src={o9} alt="Targeted distribution lists" />
            <p>Targeted distribution lists</p>
          </div>
        </div>      
        </main>
    </div>
  )
}

export default Orgmanagement
