import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import './Footer.css';
import Interface1 from '../assets/Interface1 logo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        <div className="footer-logo">
          <img src={Interface1} alt="Logo" />
        </div>

        <div className="footer-content">
          <p>© {new Date().getFullYear()} Interface11 Software Solutions PVT. LTD. All rights reserved.</p>
          <p>Simplicity is the soul of efficiency..</p>
        </div>

        <div className="footer-icons">
          <a href="https://www.facebook.com/interface11" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://x.com/interface112/" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://www.linkedin.com/company/100456262/admin/dashboard/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn />
          </a>
          <a href="https://www.instagram.com/interface11_/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <div className="footer-contact">
          <p>Email:<a href="mailto:info@interface11.com">info@interface11.org</a></p>
          <p>Phone:<a href="tel:+1234567890">+917276838776</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
