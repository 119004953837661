import React, { useState } from "react";
import "./ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    country: "",
    course: "",
    otherCourse: "",
    query: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); 
  };

  return (
    <div className="form-container">
      <h3>Would you like more information about Interface11?</h3>
      <br></br>
      <p className="dark">Contact Support</p>
      <p>7276838776</p>
      <br></br>
      <p className="dark">For General Enquiries</p>
      <p>Email: info@interface11.org</p>
 

      <form onSubmit={handleSubmit}>
        <div className="form-group-contact">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div className="form-group-contact">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <select
            name="country"
            value={formData.country}
            onChange={handleChange}
          >
            <option value="" disabled>
              Country
            </option>
            <option value="US">United States</option>
            <option value="IN">India</option>
            <option value="UK">United Kingdom</option>
          </select>
        </div>

        <div className="form-group-contact">
          <input
            type="text"
            name="course"
            placeholder="Course"
            value={formData.course}
            onChange={handleChange}
          />
          <input
            type="text"
            name="otherCourse"
            placeholder="Other Course"
            value={formData.otherCourse}
            onChange={handleChange}
          />
        </div>

        <div className="form-group-contact">
          <textarea
            name="query"
            placeholder="Query"
            value={formData.query}
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="submit-button">
          SEND
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
