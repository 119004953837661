import React from "react";
import "./AboutCards.css";
import profile from "../assets/profile.jpg";
import mission from "../assets/mission1.jpeg";
import relation from "../assets/relation.jpeg";
import innovation from "../assets/innvotion.jpg";

const AboutCards = () => {
  return (
    <div className="about-container">
      {/* <h2 className="about-title">About Us</h2> */}
      <div className="about-cards">
        <div className="about-card">
          <img src={profile} alt="Company Profile" className="card-img" />
          <div className="about-card-content">
            <h3>Company Profile</h3>
            <p>
              Interface11 Software Solutions Pvt. Ltd. is a Global IT Consulting & Training company that has
              been providing high value-added business solutions to enterprises
              of all sizes. We have proven competencies in various business and technology areas.
              Interface11 software solutions specializes in custom software development, digital transformation, and comprehensive IT consulting services. Our mission is to simplify technology for businesses, helping them streamline operations, boost productivity.Secure and scalable cloud solutions that enable flexibility and enhance operational efficiency.Comprehensive services that help organizations transition to digital-first operations.Ongoing technical support and maintenance to ensure systems run smoothly.
            </p>
          </div>
        </div>
        <div className="about-card">
          <img src={mission} alt="Mission and Vision" className="card-img" />
          <div className="about-card-content">
            <h3>Vision & Mission</h3>
            <p><strong>Vision:</strong> To become an active growth enabler for our clients.To be a global leader in digital transformation, delivering excellence in technology and setting new standards for client satisfaction, employee development, and community impact.To deliver innovative IT solutions that empower our clients, while building trust and collaboration through exceptional service and support.</p>
            <p><strong>Mission:</strong> To provide committed services and assist customers in managing time and money efficiently.This mission underscores a commitment to providing effective and efficient solutions that meet customer needs and drive growth, emphasizing collaboration, expertise, and advanced technology.</p>
          </div>
        </div>
        <div className="about-card">
          <img src={relation} alt="Relationships" className="card-img" />
          <div className="about-card-content">
            <h3>Relationship Matters</h3>
            <p>
              At Interface11 Software Solutions Pvt. Ltd. we believe in making relationships, not just clients. Whether big or small, we treat all our clients with the utmost care and exceed expectations.Relationship Matters IT Solutions is a technology-driven company dedicated to providing innovative, custom IT services with a personal touch. We believe that building lasting relationships with clients is as important as delivering cutting-edge solutions.This vision emphasizes building trust and long-term partnerships with clients, aiming to make a positive impact through both technology and relationships.Develop narratives that showcase collaborative success stories, emphasizing how client relationships have led to innovative solutions and mutual growth.
            </p>
          </div>
        </div>
        <div className="about-card">
          <img src={innovation} alt="Innovation" className="card-img" />
          <div className="about-card-content">
            <h3>Innovation in Creation</h3>
            <p>
              Innovation is key at Interface11 Software Solutions Pvt. Ltd. We constantly find creative solutions to help our clients stay ahead of the competition.Innovation in Creation IT Solutions is a modern IT services provider dedicated to bringing fresh, groundbreaking ideas to life through technology. We specialize in crafting innovative solutions that cater to the evolving needs of businesses and the digital landscape. By combining creativity with technical expertise, we enable clients to achieve transformative success in their industries.With each project, our team seeks not only to solve current challenges but to anticipate future needs—creating technology that is both innovative and sustainable.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCards;
