import React from 'react';
import Careerscontent from '../components/Careerscontent';
import ApplyJobForm from '../components/ApplyJobForm';
const Careers = () => {
  return (
    <div>
      <Careerscontent/>
      <ApplyJobForm/>
    </div>
  )
}

export default Careers
