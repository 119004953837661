import React from 'react';
import './Careerscontent.css';
import career1 from '../assets/career2.jpg';
const Careers = () => {
  return (
    <div className="careers-container">
      <div className="careers-header">
        <img src={career1} alt="Careers Background" className="header-image" />
        {/* <h1 className="careers-title">CAREERS</h1> */}
      </div>
      
      <div className="careers-content">
        <div className="careers-description">
          <p>
            Interface11 speaks to a gathering of capable, accomplished, and decided experts that drive results.
            We heartily enroll experts who are innovative masterminds and results-driven cooperative people.
            Meet any of our colleagues and you will recognize promptly that we are:
          </p>
          <ul>
            <li>Energetic experts</li>
            <li>Talented masterminds</li>
            <li>Successful issue solvers</li>
            <li>Ingenious and decided specialized masters</li>
            <li>Incredible cooperative people</li>
          </ul>
          <p>
            All positions require excellent communication skills. Strong analytical and problem-solving abilities
            are essential for Software Engineers. Kindly send your resume to info@interface11.com
          </p>
        </div>
        
        <div className="job-positions">
          <h2>Job Positions</h2>
          <div className='job-box'>
          <ul>
            <li>PHP Developers</li>
            <li>Software Developers</li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
