import React from 'react';
import Orgmanagement from '../components/Orgmanagement';

const Organisation = () => {
  return (
    <div>
      <Orgmanagement/>
    </div>
  )
}

export default Organisation
