import React from "react";
import "./ContactSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
const ContactSection = () => {
  return (
    <>
      <div className="heading">
        <h1>
          C<span>on</span>tact us
        </h1>
      </div>
      <div className="contact-container">
        <div className="address-section">
          <div>
            <h3 className="address-heading">Address</h3>
            <div className="address-bar">
            <p className="address">
    <FontAwesomeIcon icon={faMapMarkerAlt} />
    <span className="address-text"> &nbsp; 5th Floor, Waris Heights, Opp. Ellora Palace, Dhankawdi, Pune, Maharashtra - 411043</span>
</p>


              <p>
                <FontAwesomeIcon icon={faPhoneAlt} /> &nbsp;020 24373511 / 7276838776
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} />&nbsp; info@interface11.org
              </p>
            </div>
          </div>
          <div className="map-section">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.362688885221!2d73.83879457473514!3d18.45726778755148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2957dd6c8d281%3A0x45d85a2a13aebd46!2sINTERFACE%2011%20Software%20Solutions%20PVT!5e0!3m2!1sen!2sin!4v1693210982130!5m2!1sen!2sin"
              width="400"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
