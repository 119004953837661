// import React from "react";
import "./ServCards.css";
import profile from "../assets/softwaredevelopment.jpg";
import mission from "../assets/android.jpeg";
import relation from "../assets/webdevelopment.jpg";
import innovation from "../assets/digitalmarketing.jpeg";

const ServCards = () => {
    return (
        <div className="about-container">
            <h2 className="slider-title">Services</h2>
            <div className="about-cards">
                <div className="about-card">
                    <img src={profile} alt="Company Profile" className="card-img" />
                    <div className="about-card-content">
                        <h3>Software Development</h3>
                        <p>Leading software development firm specializing in creating innovative solutions tailored to meet the unique needs of our clients. We focus on delivering high-quality software products, ranging from web applications to mobile solutions, ensuring a seamless user experience and robust functionality. Our team of experienced developers, designers, and project managers work collaboratively to turn ideas into reality, leveraging the latest technologies and industry best practices.</p>
                    </div>
                </div>
                <div className="about-card">
                    <img src={mission} alt="Mission and Vision" className="card-img" />
                    <div className="about-card-content">
                        <h3>Android Development</h3>
                        <p>Specializes in Android development, creating innovative and user-friendly mobile applications that enhance user experiences across various devices. Our team of skilled developers utilizes the latest tools and technologies to build robust, scalable, and high-performance applications tailored to meet the unique needs of our clients. From concept to deployment, we ensure that every app is optimized for performance and designed with the user in mind.</p>
                    </div>
                </div>
                <div className="about-card">
                    <img src={relation} alt="Relationships" className="card-img" />
                    <div className="about-card-content">
                        <h3>Web Development</h3>
                        <p>
                        Excels in web development, delivering cutting-edge websites and web applications that are both functional and visually appealing. Our experienced team leverages modern technologies and frameworks to create responsive, user-friendly solutions tailored to our clients' specific needs. From initial design to final deployment, we prioritize performance, security, and scalability, ensuring an exceptional online experience for users.
                        </p>
                    </div>
                </div>
                <div className="about-card">
                    <img src={innovation} alt="Innovation" className="card-img" />
                    <div className="about-card-content">
                        <h3>Digital Marketing</h3>
                        <p>
                       Interface11 Software Solutions Pvt. Ltd. is dedicated to providing comprehensive digital marketing solutions that help businesses thrive online. We specialize in search engine optimization (SEO), social media marketing, content marketing, email campaigns, and pay-per-click advertising. Our data-driven strategies are designed to boost brand awareness, engage target audiences, and drive measurable results, ensuring your business stands out in the digital landscape.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServCards;
