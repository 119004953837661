import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* Hamburger button for mobile view */}
      <button className="hamburger" onClick={toggleSidebar}>
        ☰
      </button>

      {/* Sidebar menu */}
      <nav className={`sidebar ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/features/ecommerce" className="sidebar-link" onClick={closeSidebar}>
              E-commerce Platforms
            </Link>
          </li>
          <li>
            <Link to="/features/commercial" className="sidebar-link" onClick={closeSidebar}>
              Commercial Websites
            </Link>
          </li>
          <li>
            <Link to="/features/education" className="sidebar-link" onClick={closeSidebar}>
              Education
            </Link>
          </li>
          <li>
            <Link to="/features/sales" className="sidebar-link" onClick={closeSidebar}>
              Sales & Lead (CRM)
            </Link>
          </li>
          <li>
            <Link to="/features/finance" className="sidebar-link" onClick={closeSidebar}>
              Finance & Accounting
            </Link>
          </li>
          <li>
            <Link to="/features/inventory" className="sidebar-link" onClick={closeSidebar}>
              Inventory Management
            </Link>
          </li>
          <li>
            <Link to="/features/organisation" className="sidebar-link" onClick={closeSidebar}>
              Organisation Management
            </Link>
          </li>
          <li>
            <Link to="/features/tourism" className="sidebar-link" onClick={closeSidebar}>
              Tourism
            </Link>
          </li>
          <li>
            <Link to="/features/marketing" className="sidebar-link" onClick={closeSidebar}>
              Marketing
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
