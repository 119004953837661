import React from 'react';
import Training from '../components/Training';
const ECommerce = () => {
  return (
    <div>
        <Training/>
    </div>
  )
}

export default ECommerce
