import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";  
import './TechnologyCarousel.css'; 

// Importing technology icons
import icon1 from './assets/icon1.jpg';
import icon2 from './assets/icon2.jpg';
import icon3 from './assets/icon3.jpg';
import icon4 from './assets/icon4.jpg';
import icon5 from './assets/icon5.jpg';
import icon6 from './assets/icon6.jpg';
import icon7 from './assets/icon7.jpg';
import icon8 from './assets/icon8.jpg';
import icon9 from './assets/icon9.jpg';
import icon10 from './assets/icon10.jpg';

const TechnologyCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,  // Show 5 cards at a time
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const technologies = [
    icon1, icon2, icon3, icon4, icon5,
    icon6, icon7, icon8, icon9, icon10
  ];

  return (
    <div className="technology-slider">
      <h2 className="slider-title">Technology Expertise</h2>
      <Slider {...settings}>
        {technologies.map((img, index) => (
          <div key={index} className="slide-item"> 
            <img 
              src={img} 
              alt={`Technology Icon ${index + 1}`} 
              className="tech-logo" 
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Custom Next Arrow Component
const NextArrow = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <i className="fas fa-chevron-right"></i>
    </div>
  );
};

// Custom Previous Arrow Component
const PrevArrow = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <i className="fas fa-chevron-left"></i>
    </div>
  );
};

export default TechnologyCarousel;
