import React from 'react';
import Tmanagement from '../components/Tmanagement';

const Tourism = () => {
  return (
    <div>
      <Tmanagement/>
    </div>
  )
}

export default Tourism
