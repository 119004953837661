import React from 'react';
import i1 from '../assets/i1.jpg';
import i2 from '../assets/i2.jpg';
import i3 from '../assets/i3.jpg';
import i4 from '../assets/i4.png';
import i5 from '../assets/i5.jpg';
import i6 from '../assets/i6.png';
import './Invmanagement.css';


const Invmanagement = () => {
  return (
    <div className='container5'>
      <main className='content5'>
    <h1>Inventory Management</h1>
   <div className="card-grid5">
     <div className="card5">
       <img src={i1} alt="Inventory" />
       <p>Basic Inventory Control</p>
     </div>
     <div className="card5">
       <img src={i2} alt="Barcoding" />
       <p>Barcoding & Scanning</p>
     </div>
     <div className="card5">
       <img src={i3} alt="Demand Forecasting" />
       <p>Demand Forecasting</p>
     </div>
     <div className="card5">
       <img src={i4} alt="Accounting" />
       <p>Accounting Integration</p>
     </div>
     <div className="card5">
       <img src={i5} alt="Lot Tracking" />
       <p>Lot Tracking</p>
     </div>
     <div className="card5">
       <img src={i6} alt="Support" />
       <p>Support For Kits(aka Bundled Products;kitting)</p>
     </div>
   </div>      
   </main>
</div>
  )
}

export default Invmanagement;
