import React from 'react';
import Website from '../components/Website';

const Commercial = () => {
  return (
    <div>
      <Website/>
    </div>
  )
}

export default Commercial
