import React from 'react';
import f1 from '../assets/f1.png';
import f2 from '../assets/f2.jpg';
import f3 from '../assets/f3.jpg';
import f4 from '../assets/f4.jpg';
import f5 from '../assets/f5.png';
import f6 from '../assets/f6.jpg';
import f7 from '../assets/f7.jpg';
import f8 from '../assets/f8.jpg';
import f9 from '../assets/f9.jpg';
import './Accounting.css';

const Accounting = () => {
  return (
    <div className='container4'>
      <main className='content4'>
         <h1>Finance $ Accounting</h1>
        <div className="card-grid4">
        <div className="card4">
            <img src={f1} alt="Reporting" />
            <p>Reporting</p>
          </div>
          <div className="card4">
            <img src={f2} alt="Analysis" />
            <p>Analysis</p>
          </div>
          <div className="card4">
            <img src={f3} alt="Graphics" />
            <p>Graphics</p>
          </div>
          <div className="card4">
            <img src={f4} alt="Automation" />
            <p>Clear Navigation</p>
          </div>
          <div className="card4">
            <img src={f5} alt="Reporting tools" />
            <p>Reporting tools</p>
          </div>
          <div className="card4">
            <img src={f6} alt="Customization" />
            <p>Customization</p>
          </div>
          <div className="card4">
            <img src={f7} alt="Scalability" />
            <p>Scalability</p>
          </div>
          <div className="card4">
            <img src={f8} alt="Expendability" />
            <p>Expendability</p>
          </div>
          <div className="card4">
            <img src={f9} alt="Security" />
            <p>Security</p>
          </div>
        </div>      
        </main>
    </div>
  )
}

export default Accounting
