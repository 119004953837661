import React from "react";
import './Testimonials.css';
import nationall from '../assets/nationall.jpg';
import hcc from '../assets/hcc.png';
import dor from '../assets/dorr.jpg';
import moon from '../assets/moon.png';
import social from '../assets/social.png';
import mindscript from '../assets/mindscriptt.jpg';
import panchshil from '../assets/panchshil.jpg';
import graphix from '../assets/graphix.jpg';
import repairlogo from '../assets/repairlogo.png';

const testimonialsData = [
  {
    img: nationall, 
    company: "National Pharma",
    imgClass: "national-img", // Unique class for the image
    quote: "Interface11 Projects has proved to be the most efficient cloud-based sales tracking tool.",
    quoteClass: "quote-national",  // Unique class for the quote
    author: "Altaf Bagmaru",
    position: "CEO, National Pharma"
  },
  {
    img: hcc, 
    company: "HCC India",
    imgClass: "hcc-img", // Unique class for the image
    quote: "I am very pleased with the project you have done, and especially your commitment to providing a quality solution when it meant going the extra mile to do so.",
    quoteClass: "quote-hcc", // Unique class for the quote
    author: "Pritam",
    position: "Finance Director, HCC India"
  },
  {
    img: dor, 
    company: "DOR",
    imgClass: "dor-img", // Unique class for the image
    quote: "The biggest benefit we’ve seen using Interface11’s E-commerce Platform is client retention.",
    quoteClass: "quote-dor",  // Unique class for the quote
    author: "Shreyash Kapse",
    position: "Owner, DOR"
  },
  {
    img: moon, 
    company: "Moon",
    imgClass: "moon-img", // Unique class for the image
    quote: "I now feel like we have the tools to address any problem we come up against. Our only limit is our imagination.",
    quoteClass: "quote-moon",  // Unique class for the quote
    author: "Ashok Sapkal",
    position: "Owner, Moonlight School"
  },
  {
    img: social, 
    company: "Social",
    imgClass: "social-img", // Unique class for the image
    quote: "The functionality is a lot better than ZOHO and other competitors in the Market. I think this CRM is probably one of the strongest and best I have ever used.",
    quoteClass: "quote-social",  // Unique class for the quote
    author: "Sikhar Samuel",
    position: "Head-Relationship Management, Covenant Social Service"
  },
  {
    img: mindscript, 
    company: "Mindscript",
    imgClass: "mindscript-img", // Unique class for the image
    quote: "Interface11 has constantly provided quality service for all our projects. We've been happy to work with their staff, who are always looking out for our interests and have been a key element in our growth. Glad to have chosen Interface11 for all our outsourcing development needs.",
    quoteClass: "quote-mindscript",  // Unique class for the quote
    author: "Neeraj",
    position: "Owner, Mindscript"
  },
  {
    img: panchshil, 
    company: "Panchshil",
    imgClass: "panchshil-img", // Unique class for the image
    quote: "First we thought that excel would be perfect but after a few months it became obvious that we need something a lot more powerful. Then we came to Interface11‘s Inventory Management.",
    quoteClass: "quote-panchshil",  // Unique class for the quote
    author: "Rushi Handi",
    position: "Owner, Panchshil Telecom"
  },
  {
    img: graphix, 
    company: "Graphix",
    imgClass: "graphix-img", // Unique class for the image
    quote: "The most important feature of Interface11 Creator was its ease of use and ease of deploying.",
    quoteClass: "quote-graphix",  // Unique class for the quote
    author: "Dyaneshwar",
    position: "MD, Graphix Technologies"
  },
  {
    img: repairlogo, 
    company: "Repairlogo",
    imgClass: "repairlogo-img", // Unique class for the image
    quote: "Interface11 has played an important role in standardizing our program. We now have more than 1,500 clients using our services.",
    quoteClass: "quote-repairlogo",  // Unique class for the quote
    author: "Aniket Bhilare",
    position: "MD, RepairStation"
  }
];

const Testimonials = () => {
  return (
    <div className="testimonials-section">
      <h2>Testimonials</h2>
      <div className="testimonials-grid">
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img 
              src={testimonial.img} 
              alt={testimonial.company} 
              className={`testimonial-logo ${testimonial.imgClass}`} 
            />
            <p className={`testimonial-quote ${testimonial.quoteClass}`}>"{testimonial.quote}"</p>
            <p className="testimonial-author">{testimonial.author}</p>
            <p className="testimonial-position">{testimonial.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
