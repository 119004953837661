import React from 'react';
import Invmanagement from '../components/Invmanagement';

const Inventory = () => {
  return (
    <div>
      <Invmanagement/>
    </div>
  )
}

export default Inventory
