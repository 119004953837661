import React, { useState } from 'react';
import './Aboutcontent.css';

const Aboutcontent = () => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const shortContent = "Effective IT Solutions has been providing since 2016 and currently supports a number of clients across India. Our customers operate in many industry sectors including law, retail, accounting, sales, banking and corporate. Our team includes specialists in fields like software engineering, data science, cybersecurity, cloud computing, and project management.";
  
  const longContent = "Our team includes specialists in fields like software engineering, data science, cybersecurity, cloud computing, and project management. Each instructor is a seasoned practitioner, bringing deep industry knowledge, real-world applications, and insider insights to our courses. We focus on high-demand skills, including machine learning, full-stack development, data analytics, and DevOps. Training programs go beyond theory by including hands-on exercises, labs, and projects. Learners work on simulations and live case studies that replicate industry scenarios, preparing them to handle practical challenges.";

  return (
    <div className="container">
      <div className="content">
        <div className="Aboutback-imgs">
          <iframe
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.385599470074!2d73.85509127465092!3d18.466184970834327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eab966606da9%3A0xb671da7eda27f229!2sINTERFACE%2011%20Software%20Solutions%20PVT.%20LTD!5e0!3m2!1sen!2sin!4v1730105588992!5m2!1sen!2sin"
            width="600"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Google Map"
          ></iframe>
        </div>
        <div className="Aboutcontent-write">
          <h2>Who We Are</h2>
          <p>
            {isReadMore ? shortContent : shortContent + ' ' + longContent}
          </p>
          <button className="Aboutbtn-contentt" onClick={toggleReadMore}>
            {isReadMore ? "Read More" : "Read Less"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Aboutcontent;
