import React from 'react'
import About from '../components/About';
import AboutCards from '../components/AboutCards';
import Aboutcontent from '../components/Aboutcontent';

const AboutUs = () => {
  return (
    <div>
      <About/>
      <AboutCards/>
      <Aboutcontent/>
    </div>
  )
}

export default AboutUs
