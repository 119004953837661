import React from 'react';
import CRM from '../components/CRM';

const Sales = () => {
  return (
    <div>
      <CRM/>
    </div>
  )
}

export default Sales
