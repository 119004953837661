import React from 'react';
import s1 from '../assets/s1.png';
import s2 from '../assets/s2.jpg';
import s3 from '../assets/s3.png';
import s4 from '../assets/s4.png';
import s5 from '../assets/s5.png';
import s6 from '../assets/s6.jpg';
import s7 from '../assets/s7.png';
import s8 from '../assets/s8.png';
import './CRM.css';


const CRM = () => {
  return (
    <div className="container3"> 
    <main className="content3">
      <h1>Sales & Lead(CRM)</h1>
      <div className="card-grid3">
        <div className="card3">
          <img src={s1} alt="Contact" />
          <p>Contact Management</p>
        </div>
        <div className="card3">
          <img src={s2} alt="Sales Team" />
          <p>Sales Team and Customer Opportunity Management</p>
        </div>
        <div className="card3">
          <img src={s3} alt="Lead Managment" />
          <p>Lead Management for Determining High-Quality Leads</p>
        </div>
        <div className="card3">
          <img src={s4} alt="Reports" />
          <p>Reports and Dashboard</p>
        </div>
        <div className="card3">
          <img src={s5} alt="Sales Analytics" />
          <p>Sales Analytics</p>
        </div>
        <div className="card3">
          <img src={s6} alt="Mobile CRM" />
          <p>Mobile CRM</p>
        </div>
        <div className="card3">
          <img src={s7} alt="Sales Force" />
          <p>Sales Force Automation</p>
        </div>
        <div className="card3">
          <img src={s8} alt="Sales Forecasting" />
          <p>Sales Forecasting</p>
        </div>
      </div>
    </main>
  </div>
  )
}

export default CRM
