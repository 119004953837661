import React from 'react';
import tm1 from '../assets/tm1.jpg';
import tm2 from '../assets/tm2.png';
import tm3 from '../assets/tm3.png';
import tm4 from '../assets/tm4.png';
import tm5 from '../assets/tm5.jpg';
import './Tmanagement.css';


const Tmanagement = () => {
  return (
    <div className='container7'>
      <main className='content7'>
    <h1>Tourism Management</h1>
   <div className="card-grid7">
     <div className="card7">
       <img src={tm1} alt="Flexibility" />
       <p>Flexibility and Scalability</p>
     </div>
     <div className="card7">
       <img src={tm2} alt="Customized " />
       <p>Customized Tour Packaging</p>
     </div>
     <div className="card7">
       <img src={tm3} alt="Reservation" />
       <p>Reservation Management</p>
     </div>
     <div className="card7">
       <img src={tm4} alt="Quotation Management System" />
       <p>Quotation Management System</p>
     </div>
     <div className="card7">
       <img src={tm5} alt="Payment" />
       <p>Payment Flexibility</p>
     </div>
     </div>
     </main>
     </div>
  )
}

export default Tmanagement
