import React from 'react';
import './Cards.css';
import p1 from '../assets/p1.jpg';
import niit from '../assets/niit.png';
import foodexpress from '../assets/foodexpress.jpg';
import shapeup from '../assets/shapeup.png';
import sheka from '../assets/sheka.png';
import mahindra from '../assets/mahindra.jpg';
import mindscript from '../assets/mindscript.jpg';
import croma from '../assets/croma.jpg';
import national from '../assets/national.jpg';
import dor from '../assets/dor.jpg';
import ashish from '../assets/ashish.jpg';
import oneplus from '../assets/oneplus.jpg';
import wetenjoy from '../assets/wetenjoy.jpg';
import moon from '../assets/moon.png';
import hcc from '../assets/hcc.png';
import steel from '../assets/steel.png';








const clients = [
  { name: 'USIT', imgSrc: p1 },
  { name: 'NIIT', imgSrc: niit },
  { name: 'FoodXpress', imgSrc: foodexpress },
  { name: 'ShapeUp', imgSrc: shapeup },
  { name: 'Sheka', imgSrc: sheka },
  { name: 'Mahindra', imgSrc: mahindra },
  { name: 'Scripts', imgSrc: mindscript },
  { name: 'Croma', imgSrc: croma},
  { name: 'National Pharma', imgSrc: national },
  { name: 'Dor', imgSrc: dor },
  { name: 'Ashish', imgSrc: ashish },
  { name: 'Oneplus', imgSrc: oneplus },
  { name: 'Wetenjoy', imgSrc: wetenjoy },
  { name: 'Moonlight', imgSrc: moon },
  { name: 'Hcc', imgSrc: hcc },
  { name: 'steel', imgSrc: steel },
 
 
];

const Cards = () => {
  return (
    <div className="container">
      <div className="clients-section">
        <h2>Clients & Partners</h2>
        <div className="logo-grid">
          {clients.map((client, index) => (
            <div className="logo-item" key={index}>
              <img src={client.imgSrc} alt={client.name} />
            </div>
          ))}
        </div>
      </div>
      <div className="facebook-widget-section">
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FInterface11&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="340"
          height="500"
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling="no"
          frameBorder="0"
          allow="encrypted-media"
          title="Facebook Widget"
        ></iframe>
      </div>
    </div>
  );
};

export default Cards;
