import React from "react";
import Sidebar from "../components/Sidebar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "../components/features.css";

const Features = () => {
  return (
    <div className="grid">
      <div className="sideBar">
        <Sidebar />
      </div>
      <div className="mainContent">
        <Outlet />
      </div>
    </div>
  );
};

export default Features;
