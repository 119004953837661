import React from 'react';
import Market from '../components/Market';

const Marketing = () => {
  return (
    <div>
        <Market/>
      
    </div>
  )
}

export default Marketing
