import React from 'react';
import Accounting from '../components/Accounting'; 

const Finance = () => {
  return (
    <div>
        <Accounting/>
      
    </div>
  )
}

export default Finance
