import React, { useState } from 'react';
import './Content.css';
import business from '../assets/business.jpeg';

const Content = () => {
  const [isReadMore, setIsReadMore] = useState(true); // State to toggle Read More/Read Less

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore); // Toggle the state when the button is clicked
  };

  const shortContent = "Effective IT Solutions has been providing since 2016 and currently supports a number of clients across India. Our customers operate in many industry sectors including law, retail, accounting, sales, banking and corporate.Everything we do at INTERFACE11 is driven by our commitment to service. We are a friendly IT company providing all sizes of organization with leading-edge technology solutions. We were formed with the aim of assisting organizations in using modern technology with a close relationship and continual personal interaction between our staff and the client. We are a leading IT company committed to delivering innovative technology solutions that empower businesses to achieve their goals. With expertise in software development, web and mobile application development, and IT consulting, we offer a comprehensive suite of services tailored to meet the unique needs of our clients.";
  const longContent = "Our team includes specialists in fields like software engineering, data science, cybersecurity, cloud computing, and project management. Each instructor is a seasoned practitioner, bringing deep industry knowledge, real-world applications, and insider insights to our courses. We focus on high-demand skills, including machine learning, full-stack development, data analytics, and DevOps.Training programs go beyond theory by including hands-on exercises, labs, and projects. Learners work on simulations and live case studies that replicate industry scenarios, preparing them to handle practical challenges. These projects give a comprehensive understanding of end-to-end processes, from software development and data analysis to cloud deployments and cybersecurity protocols.";

  return (
    <div className="container">
      <div className="cont">
        <div className="back-img">
          <img src={business} alt="Business related" />
        </div>
        <div className="content-write">
          <h3>Better IT = Better Business.</h3>
          <p>
            {isReadMore ? shortContent : shortContent + ' ' + longContent}
          </p>

          <button className="btn-contentt" onClick={toggleReadMore}>
            {isReadMore ? "Read More" : "Read Less"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Content;
