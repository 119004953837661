import React, { useState } from 'react';
import './ApplyJobForm.css';
import career4 from '../assets/career4.jpg';
import img from '../assets/career5';

const ApplyJobForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    qualification: '',
    skills: '',
    additionalInfo: '',
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
  };

  return (
    <div className="apply-job-form">
      {/* The form content */}
      <form onSubmit={handleSubmit}>
        <h2>Apply to Job</h2>
        <div className="form-row">
          <div className="form-group">
            <label>Full Name<span>*</span></label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Name"
              required
            />
          </div>
          <div className="form-group">
            <label>Email<span>*</span></label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Phone Number<span>*</span></label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="Phone"
              required
            />
          </div>
          <div className="form-group">
            <label>Highest Qualification<span>*</span></label>
            <input
              type="text"
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              placeholder="Qualification"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Skills</label>
            <textarea
              name="skills"
              value={formData.skills}
              onChange={handleChange}
              placeholder="Skills"
            />
          </div>
          <div className="form-group">
            <label>Additional Info</label>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              placeholder="Additional Information"
            />
          </div>
        </div>

        <div className="form-group">
          <label>Upload Resume<span>*</span></label>
          <input
            type="file"
            name="resume"
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="submit-btn">SUBMIT RESUME</button>
      </form>

      <img src={career4} alt="Career" />
    </div>
  );
};

export default ApplyJobForm;
