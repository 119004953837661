import React from 'react';
import Service from '../ServicePage/Servicemain';

const Services = () => {
  return (
    <div>
        <Service/>
      
    </div>
  )
}

export default Services
