import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'; 
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Interface1 from '../assets/Interface1 logo.png';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/home" onClick={closeMobileMenu}>
          <img src={Interface1} alt="Logo" />
        </Link>
      </div>
      
      <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
        <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
      </button>

      <ul className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <li><Link to="/home" onClick={closeMobileMenu}>HOME</Link></li>
        <li><Link to="/features" onClick={closeMobileMenu}>FEATURES</Link></li>
        <li><Link to="/services" onClick={closeMobileMenu}>SERVICES</Link></li>
        <li><Link to="/portfolios" onClick={closeMobileMenu}>PORTFOLIOS</Link></li> {/* Corrected spelling */}
        <li><Link to="/clients" onClick={closeMobileMenu}>CLIENTS</Link></li>
        <li><Link to="/careers" onClick={closeMobileMenu}>CAREERS</Link></li>
        <li><Link to="/about" onClick={closeMobileMenu}>ABOUT US</Link></li>
      </ul>
      
      <div className="footer-social">
        <a href="https://www.facebook.com/interface11" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="social-icon" />
        </a>
        <a href="https://x.com/interface112/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="social-icon" />
        </a>
        <a href="https://www.instagram.com/interface11_/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="social-icon" />
        </a>
        <a href="https://www.linkedin.com/company/100456262/admin/dashboard/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
