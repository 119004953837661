import React from "react";
import e1 from "../assets/e1.png";
import e2 from "../assets/e2.png";
import e3 from "../assets/e3.jpg";
import e4 from "../assets/e4.jpg";
import e5 from "../assets/e5.png";
import e6 from "../assets/e6.jpg";
import e7 from "../assets/e7.png";
import e8 from "../assets/e8.jpg";
import e9 from "../assets/e9.jpg";
import e10 from "../assets/e10.jpg";
import e11 from "../assets/e11.png";
import e12 from "../assets/e12.png";
import "./Educationns.css";

const Educationns = () => {
  return (
    <div className="container2">
      
      <main className="card-content-educations">
      <h1>Institute Management System</h1>
        <div className="card-content-grid">
          <div className="card2">
            <img src={e1} alt="Dashboard" />
            <p>Dashboard</p>
          </div>
          <div className="card2">
            <img src={e2} alt="BranchMaster" />
            <p>BranchMaster</p>
          </div>
          <div className="card2">
            <img src={e3} alt="User Master" />
            <p>User Master</p>
          </div>
          <div className="card2">
            <img src={e4} alt="Manage Enquiries" />
            <p>Manage Enquiries</p>
          </div>
          <div className="card2">
            <img src={e5} alt="Students Master" />
            <p>Students Master</p>
          </div>
          <div className="card2">
            <img src={e6} alt="Issue Certificates" />
            <p>Issue Certificates</p>
          </div>
          <div className="card2">
            <img src={e7} alt="Students/Employee Attendance" />
            <p>Students/Employee Attendance</p>
          </div>
          <div className="card2">
            <img src={e8} alt="Manage Fee" />
            <p>Manage Fee</p>
          </div>
          <div className="card2">
            <img src={e9} alt="Employee Salary" />
            <p>Employee Salary</p>
          </div>
          <div className="card2">
            <img src={e10} alt="Communication" />
            <p>Communication (SMS,Email)</p>
          </div>
          <div className="card2">
            <img src={e11} alt="Assigment" />
            <p>Assigment</p>
          </div>
          <div className="card2">
            <img src={e12} alt="Expenses" />
            <p>Expenses</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Educationns;
