import React from 'react';
import Educationns from '../components/Educationns';

const Education = () => {
  return (
    <div>
        <Educationns/>
      
    </div>
  )
}

export default Education;
