import React from 'react';
import './Training.css';
import t1 from '../assets/t1.jpg';
import T2 from '../assets/T2.png';
import t3 from '../assets/t3.jpg';
import t4 from '../assets/t4.png';
import t5 from '../assets/t5.jpg';
import t6 from '../assets/t6.jpg';
import t7 from '../assets/t7.png';
import t8 from '../assets/t8.jpg';
import t9 from '../assets/t9.png';

function Training() {
  return (
    <div className="container"> 
      <header className="content">
        <h1>E-Commerce Platforms</h1>
      </header>
      <main className="card-grid">
        <div className="card">
          <img src={t1} alt="Content Management" />
          <p>Content management capabilities</p>
        </div>
        <div className="card">
          <img src={T2} alt="Promo Code" />
          <p>Promotion and discount code tools</p>
        </div>
        <div className="card">
          <img src={t3} alt="Checkout" />
          <p>An easy-to-use checkout</p>
        </div>
        <div className="card">
          <img src={t9} alt="SEO" />
          <p>Search engine optimized code and layout</p>
        </div>
        <div className="card">
          <img src={t4} alt="Reporting Tools" />
          <p>Reporting tools</p>
        </div>
        <div className="card">
          <img src={t5} alt="Blog" />
          <p>An integrated blog or articles section</p>
        </div>
        <div className="card">
          <img src={t6} alt="Email Marketing" />
          <p>Email marketing integration</p>
        </div>
        <div className="card">
          <img src={t7} alt="Payment Options" />
          <p>Multiple payment options</p>
        </div>
        <div className="card">
          <img src={t8} alt="Scalable" />
          <p>The ability to scale up with your platform</p>
        </div>
      </main>
    </div>
  );
}

export default Training;
