import React from 'react';
import ContactSection from '../components/ContactSection';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <div>
      <ContactSection/>
      <ContactForm/>
    </div>
  )
}

export default Contact
