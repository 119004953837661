import React from 'react';
import PortfoliosCards from '../components/PortfoliosCards';

const Portfolios = () => {
  return (
    <div>
      <PortfoliosCards/>
    </div>
  )
}

export default Portfolios
