import React, { useState } from "react";
import img7 from "../assets/img7";
import img9 from "../assets/img9.jpeg";
import it from "../assets/it.jpg";
import consultt from "../assets/consultt.jpeg";
import "./Contentbig.css";

const Contentbig = () => {
  const [showMoreIT, setShowMoreIT] = useState(false);
  const [showMoreConsulting, setShowMoreConsulting] = useState(false);

  return (
    <div className="container">
      <div className="content2">
        <div className="back-img1">
          <img src={it} alt="IT Consulting" />
        </div>
        <div className="text2">
          <h2>IT Training</h2>
          <h4>Team Of Dynamic Professionals Educating The Workforce Entering The IT Industry</h4>
          <p>
            Interface11 Software Solutions Pvt. Ltd. is a leader in Online IT Training solutions for various new generation Software Trainings like Machine-Learning, Devops, Angular, React JS, C & C++, Data Science, AWS, Java Full Stack, and much more, precisely addressing the business needs of our customers. We manage the entire technology lifecycle for you, from initial planning and strategy to implementation, optimization, and support. 
          </p>
          {showMoreIT && (
            <p>
            Our team includes specialists in fields like software engineering, data science, cybersecurity, cloud computing, and project management. Each instructor is a seasoned practitioner, bringing deep industry knowledge, real-world applications, and insider insights to our courses. We focus on high-demand skills, including machine learning, full-stack development, data analytics, and DevOps.  
            This includes network infrastructure, software deployment, cloud migration, cybersecurity, and data management, ensuring everything runs smoothly and securely.
            </p>
          )}
          <button className="btn-content2" onClick={() => setShowMoreIT(!showMoreIT)}>
            {showMoreIT ? "Show Less" : "Read More"}
          </button>
        </div>
      </div>
      
      <div className="content1">
        <div className="text1">
          <h2>IT Consulting</h2>
          <h4>We take care of the technical stuff so you don't have to.</h4>
          <p>
            Our engineers, IT consultants and project managers combine technical, interpersonal, and management skills with a thorough understanding of business processes to consistently deliver quality outcomes. Our ability to combine technology skills with an in-depth understanding of business processes is what has made us one of the fastest-growing IT service companies in our sector. We manage the entire technology lifecycle for you, from initial planning and strategy to implementation, optimization, and support.
          </p>
          {showMoreConsulting && (
            <p>
              This includes network infrastructure, software deployment, cloud migration, cybersecurity, and data management, ensuring everything runs smoothly and securely.Our consultants work closely with your team to understand your business goals and then design custom IT strategies aligned with your objectives. Whether you’re looking to scale, streamline processes, or innovate, we’ll build a roadmap that meets your unique needs.
            </p>
          )}
          <button className="btn-content" onClick={() => setShowMoreConsulting(!showMoreConsulting)}>
            {showMoreConsulting ? "Show Less" : "Read More"}
          </button>
        </div>
        <div className="back-img2 img02">
          <img src={consultt} alt="IT Training" />
        </div>
      </div>
    </div>
  );
};

export default Contentbig;
