import React, { useState } from "react";
import "./Portfolios.css";
import wetenjoy1 from "../assets/wetnjoy1.png";
import wetenjoy2 from "../assets/wetnjoy2.png";
import Wetnjoy3 from "../assets/wetnjoy3.png";
import wetnjoy4 from "../assets/wetnjoy4.png";
import dorweb1 from "../assets/dorweb1.png";
import dorweb3 from "../assets/dorweb3.png";
import dorweb4 from "../assets/dorweb4.png";
import dorweb5 from "../assets/dorweb5.png";
import USIT1 from "../assets/USIT1.png";
import USIT2 from "../assets/USIT2.png";
import USIT3 from "../assets/USIT3.png";
import Shapeup1 from "../assets/Shapeup1.png";
import Shapeup2 from "../assets/Shapeup2.png";
import Shapeup3 from "../assets/Shapeup3.png";
import Shapeup4 from "../assets/Shapeup4.png";
import pharmaweb2 from "../assets/Pharmaweb2.png";
import pharmaweb3 from "../assets/Pharmaweb3.png";
import pharmascreen1 from "../assets/pharmascreen1.png";
import pharmascreen2 from "../assets/pharmascreen2.png";
import Hccweb1 from "../assets/HCCweb1.png";
import HCCweb2 from "../assets/HCCweb2.png";
import HCCweb4 from "../assets/HCCweb4.png";
import HCCweb5 from "../assets/HCCweb5.png";
import CSSweb1 from "../assets/CSSweb1.png";
import CSSweb2 from "../assets/CSSweb2.png";
import CSSweb3 from "../assets/CSSweb3.png";
import CSSweb4 from "../assets/CSSweb4.png";
import one1 from "../assets/One1.png";
import one2 from "../assets/One2.png";
import one3 from "../assets/One3.png";
import one4 from "../assets/One4.png";
import Repair1 from "../assets/Repair1.png";
import Repair2 from "../assets/Repair2.png";
import Mindscript1 from "../assets/Mindscript1.png";
import Mindscript3 from "../assets/Mindscript3.png";
import AGT1 from "../assets/AGT1.png";
import AGT2 from "../assets/AGT2.png";
import AGT3 from "../assets/AGT3.png";
import NIIT1 from "../assets/NIIT1.jpg";
import NIIT2 from "../assets/NIIT2.jpg";
import NIIT3 from "../assets/NIIT3.jpg";

const portfolios = [
  {
    images: [dorweb1, dorweb3, dorweb4, dorweb5],
    title: " Dress On Rent",
    detailedTitle: "Project Name -Dress On Rent",
  },
  {
    images: [pharmaweb2, pharmaweb3, pharmascreen1, pharmascreen2],
    title: "National Pharma",
    detailedTitle: "Project Name - National Pharma",
  },
  {
    images: [Hccweb1, HCCweb2, HCCweb4, HCCweb5],
    title: "Hindustani Convenant Church",
    detailedTitle: "Project Name - Hindustani Convenant Church",
  },
  {
    images: [CSSweb1, CSSweb2, CSSweb3, CSSweb4],
    title: "Convenant Social Services",
    detailedTitle: "Project Name - Convenant Social Services",
  },
  {
    images: [wetenjoy1, wetenjoy2, Wetnjoy3, wetnjoy4],
    title: "WetNjoy",
    detailedTitle: "Project Name - WetNjoy",
  },
  {
    images: [USIT1, USIT2, USIT3, USIT1],
    title: "USIT",
    detailedTitle: "Project Name - USIT",
  },
  {
    images: [Shapeup1, Shapeup2, Shapeup3, Shapeup4],
    title: "Shape Up",
    detailedTitle: "Project Name - Shape Up",
  },
  {
    images: [one1, one2, one3, one4],
    title: "One+",
    detailedTitle: "Project Name - One+",
  },
  {
    images: [Repair2, Repair1, Repair2, Repair1],
    title: "Repair Station",
    detailedTitle: "Project Name - Repair Station",
  },
  {
    images: [Mindscript1, Mindscript3, Mindscript3, Mindscript1],
    title: "Mind Script",
    detailedTitle: "Project Name - Mind Script",
  },
  {
    images: [AGT1, AGT2, AGT3, AGT1],
    title: "Ashish Group Tutions",
    detailedTitle: "Project Name - Ashish Group Tutions",
  },
  {
    images: [NIIT1, NIIT2, NIIT3, NIIT1],
    title: "NIIT",
    detailedTitle: "Project Name - NIIT",
  },
];

const PortfoliosCards = () => {
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (portfolio, index) => {
    setSelectedPortfolio(portfolio);
    setCurrentIndex(index);
  };

  const handleClosePopup = () => {
    setSelectedPortfolio(null);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % selectedPortfolio.images.length;
    setCurrentIndex(newIndex);
  };

  const handlePrev = () => {
    const newIndex =
      (currentIndex - 1 + selectedPortfolio.images.length) %
      selectedPortfolio.images.length; // Wrap around to the last image
    setCurrentIndex(newIndex);
  };

  return (
    <div className="prot-heading">
      <h1>Our Projects</h1>
      <div className="portfolio-container">
        {portfolios.map((portfolio, index) => (
          <div
            key={index}
            className="portfolio-card"
            onClick={() => handleImageClick(portfolio, 0)} // Start with the first image
          >
            <img
              src={portfolio.images[0]}
              alt={portfolio.title}
              className="portfolio-image"
            />
            <div className="portfolio-text">{portfolio.title}</div>{" "}
            {/* Heading 1: Title */}
            <div className="portfolio-category">{portfolio.category}</div>{" "}
            {/* Heading 2: Category */}
          </div>
        ))}
      </div>

      {selectedPortfolio && (
        <div className="popup-overlay" onClick={handleClosePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2 className="popup-heading">{selectedPortfolio.detailedTitle}</h2>{" "}
            {/* Popup Heading 1: Detailed Title */}
            <p className="popup-description">
              {selectedPortfolio.description}
            </p>{" "}
            {/* Popup Heading 2: Description */}
            <img
              src={selectedPortfolio.images[currentIndex]}
              alt="Popup"
              className="popup-image"
            />
            <div className="thumbnail-container">
              {selectedPortfolio.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index}`}
                  className={`thumbnail-image ${
                    index === currentIndex ? "active" : ""
                  }`}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </div>
            <button className="prev-btn" onClick={handlePrev}>
              ‹
            </button>
            <button className="next-btn" onClick={handleNext}>
              ›
            </button>
            <button className="close-btn" onClick={handleClosePopup}>
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfoliosCards;
