import React from 'react';
import m1 from '../assets/m1.jpg';
import m2 from '../assets/m2.jpg';
import m3 from '../assets/m3.png';
import m4 from '../assets/m4.jpg';
import m5 from '../assets/m5.jpg';
import m6 from '../assets/m6.jpg';
import m7 from '../assets/m7.png';
import m8 from '../assets/m8.jpg';
import './Market.css';

const Market = () => {
  return (
    <div className="container8"> 
      <main className="content8">
        <h1>Marketing</h1>
        <div className='outline'>
        <p>Offline Marketing</p>
        </div>
        <div className="card-grid8">
          <div className="card8">
            <img src={m1} alt="Networking" />
            <p>Networking Speaking Engagements</p>
          </div>
          <div className="card8">
            <img src={m2} alt="Speaking" />
    
            <p>Speaking Engagements Print Publications</p>
          
          </div>
          <div className="card8">
            <img src={m3} alt="Checkout" />
            <p>An easy-to-use checkout</p>
          </div>
          <div className="card8">
            <img src={m4} alt="SEO" />
            <p>Search engine optimized code and layout</p>
          </div>
        </div>
      </main>

      {/* Online Marketing section below */}
      <main className="content9">
      <div className='outline'>
        <p>Online Marketing</p>
        </div>
        <div className="card-grid9">
          <div className="card9">
            <img src={m5} alt="Reporting Tools" />
            <p>Reporting tools</p>
          </div>
          <div className="card9">
            <img src={m6} alt="Blog" />
            <p>An integrated blog or articles section</p>
          </div>
          <div className="card9">
            <img src={m7} alt="Email Marketing" />
            <p>Email marketing integration</p>
          </div>
          <div className="card9">
            <img src={m8} alt="Payment Options" />
            <p>Multiple payment options</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Market;
