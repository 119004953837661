import React from 'react';
import about from '../assets/about.jpg';
import './About.css';
const About = () => {
  return (
    <div className='container-aboutus'>
     <div className="about-header">
     <img src={about} alt="Careers Background" className="about-image" />
        </div>
      
    </div>
  )
}

export default About
