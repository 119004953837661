import React from 'react';
import Ccards from '../components/Ccards';
import Testimonials from '../components/Testimonials';

const Clients = () => {
  return (
    <div>
      <Ccards/>
      <Testimonials/>
    </div>
  )
}

export default Clients
